import { Stack, Typography } from "@mui/material";
import React from "react";

const CommunityComp = ({ data }) => {
  return (
    <div>
      <Stack direction={"column"} spacing={4}>
        <Stack direction={"column"} spacing={2}>
          <Typography variant="h5" fontWeight={600}>
            {data.helloFromDev}
          </Typography>

          <Typography>
            <a
              onClick={() => {
                window.open(`https://www.instagram.com/codeyon_itservices/`);
              }}
              style={{
                cursor: "pointer",
                color: "green",
                fontWeight: 600,
              }}
            >
              {data.clickHer}
            </a>{" "}
            {data.toFrDev}
          </Typography>
        </Stack>

        <Typography>
          <a
            onClick={() => {
              window.open(
                `https://whatsapp.com/channel/0029VaQN2HjCxoAsvK3AeH3V`
              );
            }}
            style={{
              cursor: "pointer",
              color: "green",
              fontWeight: 600,
            }}
          >
            {data.clickHer}
          </a>{" "}
          {data.toJoinCh}
        </Typography>
      </Stack>
    </div>
  );
};

export default CommunityComp;
