import React from 'react'
import { TranslateContext } from '../../../context/TranslateContext'
import { Box, CardMedia, IconButton, Stack, Tooltip } from '@mui/material'
import { DarkMode, DarkModeOutlined, LightMode, LightModeOutlined, Logout, Translate } from '@mui/icons-material'

const Header = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <div>
            <Box p={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <CardMedia
                        component={'img'}
                        src='/assets/logo.png'
                        sx={{ height: 50, width: 50, borderRadius: 2 }}
                    />
                    <Stack direction={'row'} spacing={2}>

                        <Tooltip title={data.logout} >
                            <IconButton onClick={() => {
                                localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                                window.location.reload()
                            }}>
                                <Logout />
                            </IconButton>
                        </Tooltip>

                        {localStorage.getItem('theme') == 'dark' ?
                            <IconButton
                                size='small' onClick={() => {
                                    localStorage.setItem("theme", "light")
                                    window.location.reload()
                                }} >
                                <LightMode sx={{ height: 17, width: 17 }} />
                            </IconButton> :
                            <IconButton
                                size='small' onClick={() => {
                                    localStorage.setItem("theme", "dark")
                                    window.location.reload()
                                }} >
                                <DarkMode sx={{ height: 17, width: 17 }} />
                            </IconButton>}


                        {/* <Tooltip title={data.changeLang} >
                            <IconButton>
                                <Translate />
                            </IconButton>
                        </Tooltip> */}
                    </Stack>
                </Stack>
            </Box>
        </div>
    )
}

export default Header