import { CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import { TranslateContext } from "../../../../../context/TranslateContext";

const WhatsCRM = () => {
  const { data } = React.useContext(TranslateContext);
  return (
    <Stack direction={"column"} spacing={1}>
      <Typography fontWeight={600} variant="body2">
        {data.addOns}
      </Typography>
      <Stack
        sx={{ overflowX: "auto" }}
        direction={"row"}
        spacing={2}
        alignItems={"center"}
      >
        <CardMedia
          onClick={() =>
            window.open(
              `https://codecanyon.net/item/ai-chat-for-whatsapp-addon-for-whatscrm/52498511`
            )
          }
          sx={{
            height: 60,
            width: 60,
            cursor: "pointer",
          }}
          component={"img"}
          src="/assets/ai_chatbot_logo_512.png"
        />
      </Stack>
    </Stack>
  );
};

export default WhatsCRM;
