import React from 'react'
import Header from './components/header/Header'
import TabsComp from './TabsComp'
import { TranslateContext } from '../context/TranslateContext'
import { Box, Container } from '@mui/material'

const UserDash = () => {
    const { data } = React.useContext(TranslateContext)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Header />
            <Container maxWidth='lg'>
                <Box pb={2} mt={2}>
                    <TabsComp
                        handleChange={handleChange}
                        value={value}
                        setValue={setValue}
                        data={data}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default UserDash