import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

const Support = ({ data }) => {
    return (
        <div>
            <Stack direction={'column'} spacing={4}>
                <Stack direction={'column'} spacing={2}>
                    <Typography variant='h5' fontWeight={600}>
                        {data.whatsapp}
                    </Typography>

                    <Typography>
                        <a onClick={() => {
                            window.open(`https://wa.me/918430088300?text=hello%20there,%20support%20needed%20for%20WhatsCRM`)
                        }} style={{
                            cursor: 'pointer',
                            color: 'green'
                        }} >{data.clickHer}</a> {data.toConWa}
                    </Typography>
                </Stack>


                <Typography>
                    {data.dontHesi}
                </Typography>
            </Stack>

        </div>
    )
}

export default Support