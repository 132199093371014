import React from 'react'
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import AddProduct from './components/addProduct/AddProduct';
import Support from './components/suport/Support';
import CommunityComp from './components/community/CommunityComp';
import Profile from './components/profile/Profile';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabsComp = ({ data, value, setValue, handleChange }) => {
    return (
        <Box sx={{ bgcolor: "action.hover", borderRadius: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={data.myPurchase} {...a11yProps(0)} />
                    <Tab label={data.support} {...a11yProps(1)} />
                    <Tab label={data.community} {...a11yProps(2)} />
                    <Tab label={data.changePass} {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <AddProduct
                    data={data}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Support
                    data={data}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CommunityComp
                    data={data}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Profile
                    data={data}
                />
            </CustomTabPanel>
        </Box>
    )
}

export default TabsComp