import {
  BrowserUpdatedOutlined,
  CloudSync,
  OpenInNew,
} from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../../../context/GlobalContext";

const ActionButtonDall = ({ data, i, hitAxios }) => {
  const CON = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialog: false,
  });

  async function hitInstall() {
    const res = await hitAxios({
      path: "/api/dallham/install_app",
      post: true,
      admin: false,
      obj: { id: i?.id },
    });
  }

  async function hitUpdate() {
    const password = prompt("Please enter your dallham admin password");
    const res = await hitAxios({
      path: "/api/dallham/update_app",
      post: true,
      admin: false,
      obj: { ...state, id: i?.id, password },
    });
    if (res.data.success) {
      alert("DONT FORGET TO RESTART YOUR NODEJS APP");
    }
  }

  return (
    <div>
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
        <Tooltip title={data.openAppDoc}>
          <IconButton
            onClick={() => {
              window.open("https://doc-dallham.oneoftheprojects.com/");
            }}
            size="small"
          >
            <OpenInNew />
          </IconButton>
        </Tooltip>

        <Button
          onClick={hitUpdate}
          size="small"
          startIcon={<CloudSync />}
          sx={{
            boxShadow: 0,
            borderRadius: 2,
          }}
          variant="contained"
          color="info"
        >
          {data.updateApp}
        </Button>
        <Button
          onClick={hitInstall}
          size="small"
          startIcon={<BrowserUpdatedOutlined />}
          sx={{
            boxShadow: 0,
            borderRadius: 2,
          }}
          variant="contained"
          color="success"
        >
          {data.installApp}
        </Button>
      </Stack>
    </div>
  );
};

export default ActionButtonDall;
