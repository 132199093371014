import { Close, SaveOutlined } from '@mui/icons-material'
import { Box, Button, Container, Dialog, IconButton, Stack } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'

const EditDialog = ({ state, setState, hitAxios }) => {

    async function updateThis() {
        const res = await hitAxios({
            path: "/api/admin/update_domain_and_code",
            post: true,
            admin: true,
            obj: {
                purchase_code: state.dialogData?.purchase_code,
                install_domain: state.dialogData?.install_domain,
                id: state.dialogData?.id
            }
        })
    }

    return (
        <Dialog
            fullScreen
            open={state.dialog}
            onClose={() => setState({ ...state, dialog: false })}
        >
            <Box p={2} bgcolor={'action.hover'}>
                <IconButton onClick={() => setState({ ...state, dialog: false })} >
                    <Close />
                </IconButton>
            </Box>
            <Container maxWidth='lg'>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <TextFieldNew
                            value={state?.dialogData?.purchase_code}
                            onChange={(e) => setState({ ...state, dialogData: { ...state.dialogData, purchase_code: e.target.value } })}
                            label="Purchase code"
                        />

                        <TextFieldNew
                            value={state?.dialogData?.install_domain}
                            onChange={(e) => setState({ ...state, dialogData: { ...state.dialogData, install_domain: e.target.value } })}
                            label="Installed domain"
                        />

                        <Button
                            onClick={updateThis}
                            startIcon={<SaveOutlined />}
                            sx={{ borderRadius: 2, boxShadow: 0 }}
                            variant='contained' >
                            Update
                        </Button>
                    </Stack>
                </Box>
            </Container>
        </Dialog>
    )
}

export default EditDialog