import { Box, Button, Container, Grid, Stack } from '@mui/material'
import React from 'react'
import TextFieldNew from '../components/TextFieldNew'
import { GlobalContext } from '../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AdminLogin = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const history = useHistory()
    const [state, setState] = React.useState({})

    async function loginAdmin() {
        const res = await hitAxios({
            path: "/api/admin/login",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            const token = res.data.token
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_hamid", token)
            history.push('/hamid')
        }
    }

    return (
        <Container maxWidth='xs'>
            <Grid container minHeight={'100vh'} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={12} sm={12} lg={12} item>
                    <Stack direction={'column'} spacing={2} alignItems={'center'}>
                        <TextFieldNew
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            fullWidth
                            label="Email"
                        />
                        <TextFieldNew
                            onChange={(e) => setState({ ...state, pass: e.target.value })}
                            fullWidth
                            type='password'
                            label="Password"
                        />

                        <Button
                            onClick={loginAdmin}
                            fullWidth
                            sx={{
                                borderRadius: 2,
                                boxShadow: 0
                            }}
                            variant='contained'>
                            Login
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AdminLogin