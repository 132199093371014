import React from 'react'
import AddNew from './components/AddNew'
import { GlobalContext } from '../../../context/GlobalContext'
import ProductList from './components/ProductList'
import { Box, Divider, Stack } from '@mui/material'

const AddProduct = ({ data }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [products, setProducts] = React.useState([])

    async function getProducts() {
        const res = await hitAxios({
            path: "/api/user/get_products",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setProducts(res.data.data)
        }
    }

    React.useEffect(() => {
        getProducts()
    }, [])

    return (
        <div>
            <AddNew
                hitAxios={hitAxios}
                data={data}
                getProducts={getProducts}
            />

            <Box pt={3} pb={3}>
                <Divider />
            </Box>
            <Stack direction={'column'} spacing={2}>
                {products.map((i, key) => {
                    return (
                        <ProductList
                            i={i}
                            key={key}
                            hitAxios={hitAxios}
                            getProducts={getProducts}
                            products={products}
                            data={data}
                        />
                    )
                })}
            </Stack>
        </div>
    )
}

export default AddProduct