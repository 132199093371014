import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { GlobalContext } from '../../../context/GlobalContext'

const Profile = ({ data }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [user, setUser] = React.useState({})

    async function changePass() {
        const res = await hitAxios({
            path: "/api/user/change_pass",
            post: true,
            admin: false,
            obj: user
        })
        if (res.data.success) {
            getUser()
        }
    }

    async function getUser() {
        const res = await hitAxios({
            path: "/api/user/get_me",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setUser(res.data.data)
        }
    }

    React.useEffect(() => {
        getUser()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextFieldNew
                    disabled
                    value={user.email}
                    label={data.email}
                />

                <TextFieldNew
                    startIcon={<Typography>+</Typography>}
                    disabled
                    value={user.mobile}
                    label={data.mobile}
                />

                <TextFieldNew
                    disabled
                    value={user.name}
                    label={data.name}
                />

                <TextFieldNew
                    onChange={(e) => setUser({ ...user, newPass: e.target.value })}
                    label={data.newPass}
                />

                <Button
                    onClick={changePass}
                    sx={{ boxShadow: 0, borderRadius: 2 }}
                    variant='contained'>
                    {data.submit}
                </Button>
            </Stack>
        </div>
    )
}

export default Profile