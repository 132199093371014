import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, CardMedia } from '@mui/material';

const LicenseList = ({ listItem, setList, hitAxios }) => {
    return (
        <div>
            {listItem ?
                <Box bgcolor={'action.hover'} height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...listItem].reverse()}
                        columns={[
                            {
                                headerName: "Logo", field: 'logo', flex: 0.15,
                                renderCell: row => <CardMedia
                                    sx={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: 2
                                    }}
                                    component={'img'}
                                    src={JSON.parse(row?.row?.json)?.item?.previews?.icon_preview?.icon_url}
                                />
                            },
                            {
                                headerName: "Id", field: 'id', flex: 0.2, renderCell: row => {
                                    return (
                                        JSON.parse(row?.row?.json)?.item?.id
                                    )
                                }
                            },
                            {
                                headerName: "Purchase code", field: 'purchase_code', flex: 0.6,
                            },
                            {
                                headerName: "Product", field: 'name', flex: 1, renderCell: row => {
                                    return (
                                        JSON.parse(row?.row?.json)?.item?.name
                                    )
                                }
                            },
                            {
                                headerName: "Buyer", field: 'buyer', flex: 0.3, renderCell: row => {
                                    return (
                                        JSON.parse(row?.row?.json)?.buyer
                                    )
                                }
                            },
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        sx={{
                            boxShadow: 0,
                            borderRadius: 4,
                            border: 0,
                            fontSize: 13,
                            padding: 2
                        }}
                    />
                </Box> : ""
            }
        </div>
    )
}

export default LicenseList