import { Button, Chip, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../components/TextFieldNew'
import LicenseList from './LicenseList'
import { GlobalContext } from '../../context/GlobalContext'

const GenerateLicense = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [listItem, setList] = React.useState([])
    const [state, setState] = React.useState({
        license: "Regular License"
    })

    async function getLicense() {
        const res = await hitAxios({
            path: "/api/admin/get_custom_license",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_hamid")
        })
        if (res.data.success) {
            setList(res.data.data)
        }
    }

    async function generate() {
        const res = await hitAxios({
            path: "/api/admin/generate_license",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getLicense()
        }
    }

    function nameToUsername(name) {
        // Normalize the name: trim, convert to lowercase, remove special characters, replace spaces with underscores
        return name
            .trim()
            .toLowerCase()
            .replace(/[^\w\s]/gi, '') // Remove special characters
            .replace(/\s+/g, '_'); // Replace spaces with underscores
    }

    React.useEffect(() => {
        getLicense()
    }, [])

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <TextFieldNew
                    onChange={(e) => setState({
                        ...state,
                        buyerName: e.target.value,
                        buyerUsername: nameToUsername(e.target.value)
                    })}
                    label="Buyer name"
                />

                <TextFieldNew
                    type='number'
                    onChange={(e) => setState({ ...state, amount: e.target.value })}
                    label="Amount paid by customer"
                />

                <TextFieldNew
                    disabled
                    value={state.buyerUsername}
                    label="Buyer username"
                />

                <TextFieldNew
                    onChange={(e) => setState({ ...state, licenseCode: e.target.value })}
                    label="Old license code to clone"
                />

                <Stack direction={'row'} spacing={2}>
                    <Chip
                        onClick={() => setState({ ...state, license: "Regular License" })}
                        variant={state.license === "Regular License" ? 'filled' : 'outlined'}
                        label="Regular"
                    />
                    <Chip
                        onClick={() => setState({ ...state, license: "Extended License" })}
                        variant={state.license === "Extended License" ? 'filled' : 'outlined'}
                        label="Extended"
                    />
                </Stack>

                <Button
                    onClick={generate}
                    size='small'
                    variant='contained'
                    sx={{
                        borderRadius: 2,
                        boxShadow: 0
                    }}
                >
                    Generate license
                </Button>

                <Divider />

                <LicenseList
                    listItem={listItem}
                    hitAxios={hitAxios}
                    setList={setList}
                />

            </Stack>
        </div>
    )
}

export default GenerateLicense