import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const FrontEnd = () => {
    const history = useHistory()
    return (
        history.push('/user')
    )
}

export default FrontEnd