import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import TextFieldNew from "../../../components/TextFieldNew";
import { GlobalContext } from "../../../context/GlobalContext";

const Dash = () => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    code: "",
    response: null,
  });

  async function handleChek() {
    const res = await hitAxios({
      path: "/api/admin/check_license",
      post: true,
      admin: true,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, response: res.data.data });
    }
  }

  return (
    <div>
      <Stack direction={"column"} spacing={2}>
        <TextFieldNew
          onChange={(e) => setState({ ...state, code: e.target.value })}
          label="Enter purchase code"
        />
        <Button onClick={handleChek} size="small" variant="contained">
          Check
        </Button>
        {state.response && (
          <Typography component="pre" variant="body1">
            {JSON.stringify(state.response, null, 2)}
          </Typography>
        )}
      </Stack>
    </div>
  );
};

export default Dash;
