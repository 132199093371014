import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { DataGrid } from '@mui/x-data-grid';
import { Box, CardMedia, Chip, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import { Delete, Edit, Login } from '@mui/icons-material'
import EditDialog from './components/EditDialog';

const Products = () => {
    const [state, setState] = React.useState({
        dialogData: {},
        dialog: false
    })
    const [products, setProducts] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    const [show, setShow] = React.useState("PRODUCTS")

    async function getProducts(showUsers) {
        setShow(showUsers ? "USERS" : "PRODUCTS")
        const res = await hitAxios({
            path: "/api/product/get_all",
            post: true,
            admin: true,
            obj: {
                showUsers: showUsers ? true : false
            }
        })
        if (res.data.success) {
            console.log(res.data.data)
            setProducts(res.data.data)
        }
    }

    async function direcLogin(e) {
        const res = await hitAxios({
            path: "/api/admin/direct_login",
            post: true,
            admin: true,
            obj: { uid: e }
        })

        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", res.data.token)
            window.open('/user')
        }
    }

    async function delProduct(id) {
        if (window.confirm("Are you sure ?")) {
            const res = await hitAxios({
                path: "/api/admin/del_product",
                post: true,
                admin: true,
                obj: {
                    id
                }
            })
            if (res.data.success) {
                getProducts()
            }
        }
    }


    async function delUser(id) {
        if (window.confirm("Are you sure ?")) {
            const res = await hitAxios({
                path: "/api/admin/del_user",
                post: true,
                admin: true,
                obj: {
                    id
                }
            })
            if (res.data.success) {
                getProducts(true)
            }
        }
    }

    React.useEffect(() => {
        getProducts()
    }, [])

    function showProducts(row) {
        const column = [{
            headerName: "Code", field: 'purchase_code', flex: 1
        }]
        return column
    }

    return (
        <div>

            <Box mb={2}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Chip
                        variant={show === "PRODUCTS" ? 'filled' : 'outlined'}
                        onClick={() => getProducts()}
                        label="Products"
                    />
                    <Chip
                        variant={show === "USERS" ? 'filled' : 'outlined'}
                        onClick={() => getProducts(true)}
                        label="Users"
                    />
                </Stack>
            </Box>
            {products ?
                <Box bgcolor={'action.hover'} height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...products].reverse()}
                        columns={
                            show === "PRODUCTS" ?
                                [
                                    {
                                        headerName: "Login", field: 'login', flex: 1,
                                        renderCell: row => <IconButton
                                            onClick={() => direcLogin(row.row.uid)}>
                                            <Login />
                                        </IconButton>
                                    },
                                    {
                                        headerName: "Logo", field: 'logo', flex: 1,
                                        renderCell: row => <CardMedia
                                            sx={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: 2
                                            }}
                                            component={'img'}
                                            src={row?.row?.product_logo}
                                        />
                                    },
                                    {
                                        headerName: "Code", field: 'purchase_code', flex: 1
                                    },
                                    {
                                        headerName: "Username", field: 'cc_username', flex: 1
                                    },
                                    {
                                        headerName: "Domain", field: 'install_domain', flex: 1
                                    },
                                    {
                                        headerName: "User Email", field: 'user_email', flex: 1, renderCell: row => {
                                            return row?.row?.userData?.email
                                        }
                                    },
                                    {
                                        headerName: "User Name", field: 'user_name', flex: 1, renderCell: row => {
                                            return <a style={{
                                                padding: 2, backgroundColor: row?.row?.purchase_date ? 'rgba(21, 255, 0, 0.19)' : 'rgba(255, 0, 0, 0.19)'
                                            }}>{row?.row?.userData?.name}</a>
                                        }
                                    },
                                    {
                                        headerName: "Name", field: 'product_name', flex: 1
                                    },
                                    {
                                        headerName: "Purchase Date", field: 'purchase_date', flex: 1, renderCell: row => moment(row.row?.purchase_date).format("DD MM YY | hh:mmA")
                                    },
                                    {
                                        headerName: "Edit", field: 'edit', flex: 1,
                                        renderCell: row => <IconButton
                                            onClick={() => setState({ ...state, dialogData: row.row, dialog: true })} >
                                            <Edit />
                                        </IconButton>
                                    },
                                    {
                                        headerName: "Delete", field: 'del', flex: 1,
                                        renderCell: row => <IconButton color='error' onClick={() => delProduct(row.row?.id)} >
                                            <Delete />
                                        </IconButton>
                                    }
                                ] :

                                [
                                    {
                                        headerName: "Login", field: 'login', flex: 1,
                                        renderCell: row => <IconButton
                                            onClick={() => direcLogin(row.row.uid)}>
                                            <Login />
                                        </IconButton>
                                    },
                                    {
                                        headerName: "User Email", field: 'user_email', flex: 1, renderCell: row => {
                                            return row?.row?.email
                                        }
                                    },
                                    {
                                        headerName: "User Name", field: 'user_name', flex: 1, renderCell: row => {
                                            return <a style={{
                                                padding: 2, backgroundColor: row?.row?.purchase_date ? 'rgba(21, 255, 0, 0.19)' : 'rgba(255, 0, 0, 0.19)'
                                            }}>{row?.row?.name}</a>
                                        }
                                    },
                                    {
                                        headerName: "Code", field: 'purchase_code', flex: 1, renderCell: row => {
                                            return row?.row?.userData?.purchase_code || "NA"
                                        }
                                    },
                                    {
                                        headerName: "Domain", field: 'install_domain', flex: 1, renderCell: row => {
                                            return row?.row?.userData?.install_domain || "NA"
                                        }
                                    },
                                    {
                                        headerName: "Name", field: 'product_name', flex: 1, renderCell: row => {
                                            return row?.row?.userData?.product_name || "NA"
                                        }
                                    },
                                    {
                                        headerName: "Purchase Date", field: 'purchase_date', flex: 1,
                                        renderCell: row => moment(row?.row?.userData?.purchase_date).format("DD MM YY | hh:mmA") || "NA"
                                    },
                                    {
                                        headerName: "Delete", field: 'del', flex: 1,
                                        renderCell: row => <IconButton color='error' onClick={() => delUser(row.row?.id)} >
                                            <Delete />
                                        </IconButton>
                                    }
                                ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        sx={{
                            boxShadow: 0,
                            borderRadius: 4,
                            border: 0,
                            fontSize: 13,
                            padding: 2
                        }}
                    />
                </Box> : ""
            }

            <EditDialog
                hitAxios={hitAxios}
                state={state}
                setState={setState}
            />
        </div>
    )
}

export default Products