import { Alert, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../../components/TextFieldNew'

const AddNew = ({ data, hitAxios, getProducts }) => {
    const [state, setState] = React.useState({})

    async function handleAddProduct() {
        const res = await hitAxios({
            path: "/api/user/add_product",
            post: true,
            admin: false,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, domain: "", purchase_code: "" })
            getProducts()
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>

                <Alert severity="info">{data.purchaseDomainNote}</Alert>

                <TextFieldNew
                    value={state.purchase_code}
                    onChange={(e) => setState({ ...state, purchase_code: e.target.value })}
                    label={data.enterPurchaseCode}
                />
                <TextFieldNew
                    value={state.domain}
                    onChange={(e) => setState({ ...state, domain: e.target.value })}
                    startIcon={<Typography>https://</Typography>}
                    label={data.enterDomain}
                />

                <Button
                    onClick={handleAddProduct}
                    variant='contained'
                    sx={{ boxShadow: 0, borderRadius: 2 }}
                >
                    {data.addProduct}
                </Button>
            </Stack>
        </div>
    )
}

export default AddNew